import groupBy from "lodash/groupBy"
import map from "lodash/map"
import merge from "lodash/merge"
import reduce from "lodash/reduce"
import { BUILDING_IMAGE_SUFFIX, EXTERIOR_IMAGE_SUFFIX } from "../const"

const defaultExtractOptions = {
  maxByGroup: {
    room: 1,
    building: 3,
  },
  // NOTE: 指定が無い場合は全部出す。指定があって空の場合は1つも出さない
  includeTypesByGroup: {
    // exterior: [],
    // room: [],
    // building: []
  },
}

const getImageKeyFromProperty = ({ property }) => {
  const { floorPlanNumberOfRooms, floorPlanType, exclusiveArea } = property
  return [floorPlanNumberOfRooms, floorPlanType, exclusiveArea].join("-")
}

const getImagesOfRoomByImageKey = ({
  imagesByImageKey,
  imageKey,
  extractOptions,
}) => {
  const exterior = imagesByImageKey[EXTERIOR_IMAGE_SUFFIX]
    ? imagesByImageKey[EXTERIOR_IMAGE_SUFFIX]
    : []
  const room = imagesByImageKey[imageKey] || []
  const building = imagesByImageKey[BUILDING_IMAGE_SUFFIX] || []

  const beforeExtract = {
    exterior,
    room,
    building,
  }

  return extractByOptions({
    imagesByImageKey: beforeExtract,
    extractOptions,
  })
}

const extractByOptions = ({ imagesByImageKey, extractOptions }) => {
  const mergedOptions = merge({}, defaultExtractOptions, extractOptions)
  const { maxByGroup, includeTypesByGroup } = mergedOptions
  const typeFiltered = reduce(
    imagesByImageKey,
    (result, images, group) => {
      const includeTypes = includeTypesByGroup[group]
      if (!includeTypes) {
        result[group] = images
      } else {
        result[group] = images.filter((image) =>
          includeTypes.includes(image.type)
        )
      }
      return result
    },
    {}
  )
  return reduce(
    typeFiltered,
    (result, images, group) => {
      const maxCount = maxByGroup[group]
      const countedImages = []
      const countByType = {}
      images.forEach((image) => {
        const { type } = image
        if (!countByType[type]) {
          countByType[type] = 0
        }
        if (maxCount <= countByType[type]) {
          return
        }
        countByType[type] = countByType[type] + 1
        countedImages.push(image)
      })
      result[group] = countedImages
      return result
    },
    {}
  )
}

export const getImagesByPropertyIdOfOneApartment = ({
  properties,
  apartmentImages,
  extractOptions = {},
}) => {
  const result = {}
  const { imagesByImageKey } = apartmentImages
  for (const property of properties) {
    const { propertyId } = property
    const imageKey = getImageKeyFromProperty({ property })
    const { exterior, room, building } = getImagesOfRoomByImageKey({
      imagesByImageKey,
      imageKey,
      extractOptions,
    })

    const hasImage =
      exterior.length > 0 || room.length > 0 || building.length > 0
    if (hasImage) {
      result[propertyId] = {
        exterior,
        room,
        building,
      }
    }
  }
  return result
}

const getImageUrlsByPropertyIdOfOneApartment = ({
  properties,
  apartmentImages,
  extractOptions = {},
}) => {
  const result = {}
  const { imagesByImageKey } = apartmentImages
  for (const property of properties) {
    const { propertyId } = property
    const imageKey = getImageKeyFromProperty({ property })
    const { exterior, room, building } = getImagesOfRoomByImageKey({
      imagesByImageKey,
      imageKey,
      extractOptions,
    })
    const images = [...exterior, ...room, ...building]
    result[propertyId] = map(images, "url")
    result[propertyId].sort()
  }
  return result
}

export const getImagesByPropertyId = ({
  properties,
  apartmentImages,
  apartmentImagesByMasterId,
  extractOptions,
}) => {
  if (apartmentImagesByMasterId) {
    const propertiesByMasterId = groupBy(properties, "masterId")
    return reduce(
      propertiesByMasterId,
      (result, properties, masterId) => {
        const apartmentImages = apartmentImagesByMasterId[masterId]
        if (!apartmentImages) {
          return result
        }
        const tmp = getImagesByPropertyIdOfOneApartment({
          properties,
          apartmentImages,
          extractOptions,
        })
        return Object.assign(result, tmp)
      },
      {}
    )
  } else {
    return getImagesByPropertyIdOfOneApartment({
      properties,
      apartmentImages,
      extractOptions,
    })
  }
}

export const getImageUrlsByPropertyId = ({
  apartmentImagesByMasterId,
  apartmentImages,
  properties,
  extractOptions,
}) => {
  // NOTE: getImagesByPropertyIdと結構冗長なコードがある。将来的に共通化？
  if (apartmentImagesByMasterId) {
    const propertiesByMasterId = groupBy(properties, "masterId")
    return reduce(
      propertiesByMasterId,
      (result, properties, masterId) => {
        const apartmentImages = apartmentImagesByMasterId[masterId]
        if (!apartmentImages) {
          return result
        }
        const tmp = getImageUrlsByPropertyIdOfOneApartment({
          properties,
          apartmentImages,
          extractOptions,
        })
        return Object.assign(result, tmp)
      },
      {}
    )
  } else {
    return getImageUrlsByPropertyIdOfOneApartment({
      properties,
      apartmentImages,
      extractOptions,
    })
  }
}
